import React from 'react'
import { Wrapper } from 'src/components/layout/wrapper'

export default () => (
  <Wrapper subtitle='Wir über uns'>
    <h1>Wir über uns</h1>

    <p>Wir liefern seit über 20 Jahren innovative Filterüberwachungen und Regenerations-Steuerungen für Diesel-Partikelfiltersysteme.<br/>
      Für den Einsatz mit Baumaschinen, Generatoren, Lokomotiven - unabhängig von der Motorenleistung.<br/>
      Dazu zählen die früheren Typen ALU703, die PIO-Reihe mit den Überwachungen PIO120 und PIO2000, bis hin zur aktuellen Version PIO-CAN-USB.
      <br/><br/>
      Unsere Produkte finden Einsatz bei OEM‘s und im Retrofitbereich.<br/>
      Durch kontinuierliche Weiterentwicklung passen sich die Produkte den Marktbedürfnissen an.</p>
  </Wrapper>
)
